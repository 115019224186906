<template>
	<div class="monitor-map">
		<div class="map-container">
			<data-map v-show="mode === 'dataMap'"></data-map>
			<modal-com v-show="mode === 'modalCom'"></modal-com>
			<div class="left-win__floating">
				<left-win></left-win>
			</div>
			<div class="right-win__floating">
				<right-win></right-win>
			</div>
		</div>
		<div class="map-flags">
			<el-row>
				<el-col :span="12">
					<span class="module-title">站点图例说明</span>
					<br />
					<span class="flag-item">
						<img src="@/assets/monitorMap/png/now.png" />
						当前位置及行驶方向
					</span>
					<span class="flag-item">
						<img src="@/assets/monitorMap/png/siteP.png" />
						公交站点
					</span>
					<span class="flag-item">
						<img src="@/assets/monitorMap/png/firstSite.png" />
						始发站点
					</span>
					<span class="flag-item">
						<img src="@/assets/monitorMap/png/depot.png" />
						辅助公交站
					</span>
					<span class="flag-item">
						<img src="@/assets/monitorMap/png/depotMast.png" />
						主公交场站
					</span>
				</el-col>
				<el-col :span="12">
					<span class="module-title">车辆异常状态</span>
					<br />
					<span class="flag-item">
						<img src="@/assets/monitorMap/png/exectioncar.png" />
						车辆异常
					</span>
					<span class="flag-item">
						<img src="@/assets/monitorMap/png/stopcar.png" />
						车辆停止
					</span>
					<span class="flag-item">
						<img src="@/assets/monitorMap/png/outlinecar.png" />
						车辆离线
					</span>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
import LeftWin from "./leftWin/index.vue";
import RightWin from "./rightWin/RightWin.vue";
import DataStore from "./DataStore";
import ModalCom from "./container/ModalCom.vue";
import DataMap from "./container/DataMap.vue";
export default {
	provide() {
		return {
			dataStore: new DataStore()
		};
	},
	data() {
		return {
			mode: "dataMap" // dataMap modalCom
		};
	},
	components: { LeftWin, RightWin, ModalCom, DataMap }
};
</script>
<style lang="scss" scoped>
.monitor-map {
	height: 100%;
	.map-container {
		height: calc(100% - 65px);
		position: relative;
		.left-win__floating {
			position: absolute;
			top: 20px;
			left: 20px;
			bottom: 20px;
			height: calc(100% - 40px);
			overflow: auto;
			background-color: transparent;
			width: 240px;
			z-index: 1000;
		}

		.right-win__floating {
			position: absolute;
			top: 20px;
			right: 20px;
			bottom: 20px;
			height: calc(100% - 40px);
			overflow: auto;
			min-width: 180px;
			z-index: 1000;
		}
	}
	.map-flags {
		height: 65px;
		background-color: #fff;
		padding-left: 50px;
		box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.1);
		font-size: 14px;
		padding-top: 10px;
		img {
			width: 20px;
			height: 20px;
		}
		.flag-item {
			display: inline-block;
			margin-top: 9px;
			margin-right: 20px;
		}
	}
}
</style>

<style lang="scss">
.module-title {
	font-size: 12px;
	color: #527db3;
	span:nth-child(2) {
		float: right;
	}
}
.module-title::before {
	display: inline-block;
	content: "";
	width: 2px;
	height: 16px;
	background-color: #527db3;
	vertical-align: text-top;
	margin-right: 5px;
}
</style>
