<template>
	<div class="map-wrapper">
		<div ref="container" class="map-container" id="baiduMap"></div>
		<station ref="station"></station>
	</div>
</template>

<script>
import Gcoord from "gcoord";
import PreTrackDB from "./PreTrackDB";
// import createMap from "@/utils/bMap";
import startImg from "@/assets/startIcon.png";
import endImg from "@/assets/endIcon.png";
import depot from "@/assets/monitorMap/depot.svg";
import depotMast from "@/assets/monitorMap/depotMast.svg";
import site from "@/assets/monitorMap/sitePoint.svg";
import firstSite from "@/assets/monitorMap/firstSite.svg";
import busOn from "@/assets/monitorMap/online.svg";
import busOff from "@/assets/monitorMap/offline.svg";

import startIcon from "@/assets/startIcon.png";
import endIcon from "@/assets/endIcon.png";
// import StationMap from "../../bigScreenNew/line/station";
import Station from "./Station.vue";
import gcoord from "gcoord";
import LuShu from "@/common/js/BMapLib/LuShu.js";
import DataStore from "../DataStore";
export default {
	inject: ["dataStore"],
	name: "DataMap",
	mounted() {
		this.initMap();
		this.lushu = null;
		this.preTrackDB = new PreTrackDB();

		this.dataStore.on("start", deviceId => {
			this.initLushu(deviceId);
		});
		this.dataStore.on("pause", () => {
			this.pauseLushu();
		});
		this.dataStore.on("stop", () => {
			this.stopLushu();
		});
		this.dataStore.on(
			"progress",
			val => {
				if (this.lushu) {
					const total = this.lushu._path.length;
					this.lushu.i = Math.floor((val / 100) * total);
					this.startLushu();
				}
			},
			"dataMap"
		);
		this.dataStore.on("routeIds", () => {
			console.log("z7klan");
			this.focusLines();
		});
		this.dataStore.on("vehicleId", () => {
			this.focusCar();
		});
		this.dataStore.on("findCar", vehicleId => {
			this.findCarByVehicleId(vehicleId);
		});
	},
	activated() {
		// this.intervalId = setInterval(this.updateFunc.bind(this), 5 * 60 * 1000);
	},
	deactivated() {
		// if (this.intervalId) {
		//     clearInterval(this.intervalId);
		//     this.intervalId = "";
		// }
	},
	data() {
		return {
			isCar: false // 当前地图显示的是线路或者车辆
		};
	},
	methods: {
		focusCar() {
			this.isCar = true;
			this.clearOverlays();
			this.loadCar();
		},
		focusLines() {
			this.clearOverlays();
			this.getPreTrack();
			this.getDepot();
			this.getSite();

			this.loadData();

			this.isCar = false;
		},
		clearOverlays() {
			if (this.trackMap) {
				this.trackMap.clearOverlays();
			}
		},
		// 加载所有线路的车辆数据
		async loadData() {
			const routeIds = this.dataStore.routeIds;
			let restRecord = 0,
				vlist = [],
				pageIndex = 1;
			do {
				const result = await this.$http.post("/realtime/getRTVehicleInfo", {
					pageIndex,
					pageSize: 200,
					orgId: routeIds.join(",")
				});
				const { total, list } = result.detail;
				vlist = vlist.concat(list);
				restRecord = total - vlist.length;
				pageIndex++;
			} while (restRecord > 0);
			if (vlist.length > 0) {
				vlist.forEach(v => {
					const { vhNo: plateNum, vhId: vehicleId, devicePosList, online } = v;
					const { lng, lat } = devicePosList[0];
					if (lng && lat) {
						const point = Gcoord.transform([lng, lat], Gcoord.WGS84, Gcoord.BD09);
						this.addVehicleMarker(plateNum, vehicleId, point[0], point[1], online);
					}
				});
			}
		},
		// 加载车辆数据
		loadCar() {
			this.$http
				.post("/waybillActual/queryVehiclePosition", {
					vehicleIds: this.dataStore.vehicleId
				})
				.then(res => {
					const vehicleRT = res.detail[0];
					const [lng, lat] = Gcoord.transform([vehicleRT.lng, vehicleRT.lat], Gcoord.WGS84, Gcoord.BD09);
					this.addVehicleMarker(vehicleRT.vehicleNo, vehicleRT.vehicleId, lng, lat, vehicleRT.isOnline);
					this.trackMap.centerAndZoom(new BMapGL.Point(lng, lat), 15);
				});
		},
		// 跟新车辆位置
		async updateFunc() {
			if (this.isCar) {
				//
			} else {
				//
			}
		},
		startLushu() {
			this.lushu && this.lushu.start();
		},
		stopLushu() {
			this.lushu && this.lushu.stop();
			// this.focusSelectedVehicle();
		},
		pauseLushu() {
			this.lushu && this.lushu.pause();
		},
		addVehicleMarker(plateNum, vehicleId, lng, lat, online) {
			let marker = this.getOverlay(vehicleId);
			if (marker) {
				marker.setPosition(new BMapGL.Point(lng, lat));
			} else {
				const icon = online ? busOn : busOff;
				const m = new BMapGL.Marker(new BMapGL.Point(lng, lat), {
					icon: new BMapGL.Icon(icon, new BMapGL.Size(32, 32))
				});
				m.addEventListener("click", ev => {
					const curMarker = ev.currentTarget;
					this.dataStore.vehicleId = curMarker.id;
					this.dataStore.trigger("vehicleId", curMarker.id);
				});
				m.id = vehicleId;
				this.trackMap.addOverlay(m);
				let label = new BMapGL.Label(plateNum, { offset: new BMapGL.Size(-25, -42) });
				m.setLabel(label);
			}
		},
		getOverlay(id) {
			const overlays = this.trackMap.getOverlays();
			return overlays.find(v => v.id === id);
		},
		initMap() {
			this.trackMap = new BMapGL.Map("baiduMap", { minZoom: 10, maxZoom: 18 });
			this.trackMap.enableScrollWheelZoom();
			this.trackMap.centerAndZoom(new BMapGL.Point(89.187441, 42.957145), 15);
		},
		getDepot() {
			const routeIds = this.dataStore.routeIds;
			this.$http
				.post("/home/queryStationByRoute", {
					routeIds: routeIds.join(",")
				})
				.then(({ detail }) => {
					if (detail && detail.length > 0) {
						detail.forEach(station => {
							const [lng, lat] = Gcoord.transform([station.lng, station.lat], Gcoord.WGS84, Gcoord.BD09);
							this.addDepot(lng, lat, station);
						});
					}
				});
		},
		addDepot(lng, lat, station) {
			let icon = depotMast;
			if (station.type == 1) {
				icon = depot;
			}
			const m = new BMapGL.Marker(new BMapGL.Point(lng, lat), {
				icon: new BMapGL.Icon(icon, new BMapGL.Size(30, 30))
			});
			m.addEventListener("click", () => {
				this.$refs.station.init(station);
			});
			this.trackMap.addOverlay(m);
		},
		getPreTrack() {
			// console.log("-----this.dataStore.routeIds", this.dataStore.routeIds);
			// this.dataStore.routeIds.forEach(routeId => {
			// 	this.preTrackDB.read(routeId).then(detail => {
			// 		this.setPreTrack(detail);
			// 		// this.drawLine(detail, "blue");
			// 	});
			// });
		},
		setPreTrack(list) {
			if (!list || list.length == 0) {
				return;
			}
			var index = 0;
			this.dataStore.routeIds.forEach(routeId => {
				console.log("-----routeId", routeId);
				//上行
				const arrPois = list.map(p => {
					if (routeId == p.routeSite.routeId && p.routeSite.goType == "1") {
						const tp = Gcoord.transform([p.siteInfo.lng, p.siteInfo.lat], Gcoord.WGS84, Gcoord.BD09);
						return new BMapGL.Point(tp[0], tp[1]);
					}
					return "";
				});
				const arr = arrPois.filter(Boolean);
				console.log("-----arr", arr);
				if (arr.length > 0) {
					index++;
					const polyline = new BMapGL.Polyline(arr, { strokeColor: "blue", strokeWeight: 5, strokeOpacity: 1 });
					polyline.routeId = routeId;
					this.trackMap.addOverlay(polyline);
					this.addStartEndMarker(arr[0], arr[arr.length - 1], startImg, endImg);
					if (index == 1) {
						var count = Math.round(arr.length / 2);
						// console.log("-----count", count);
						this.trackMap.centerAndZoom(arr[count], 15);
					}
				}
			});
		},
		addStartEndMarker(startPos, endPos, startFlag, endFlag) {
			if (startPos && endPos) {
				var startIcon = new BMapGL.Icon(startFlag, new BMapGL.Size(25, 30));
				var endIcon = new BMapGL.Icon(endFlag, new BMapGL.Size(25, 30));
				let marker = new BMapGL.Marker(startPos, {
					icon: startIcon
				});
				let endmarker = new BMapGL.Marker(endPos, {
					icon: endIcon
				});
				this.trackMap.addOverlay(marker);
				this.trackMap.addOverlay(endmarker);
			}
		},
		getSite() {
			console.log("this.dataStore.routeIds", this.dataStore.routeIds);
			this.$http
				.post("/site/info/queryByRouteIds", {
					routeIds: this.dataStore.routeIds.join(",")
				})
				.then(res => {
					if (res.detail && res.detail.length > 0) {
						res.detail.forEach(v => {
							if (v) {
								this.addSite(v.siteInfo.lng, v.siteInfo.lat, v.routeSite.num);
							}
						});
						this.setPreTrack(res.detail);
					}
				});
		},
		addSite(lng, lat, num) {
			const result = Gcoord.transform([lng, lat], Gcoord.WGS84, Gcoord.BD09);
			let icon = "";
			if (num == 0) {
				icon = firstSite;
			} else {
				icon = site;
			}
			const m = new BMapGL.Marker(new BMapGL.Point(result[0], result[1]), {
				icon: new BMapGL.Icon(icon, new BMapGL.Size(30, 30))
			});
			this.trackMap.addOverlay(m);
		},
		async initLushu(deviceId) {
			if (deviceId) {
				if (!this.lushu) {
					this.clearOverlays();
					const nowD = new Date().Format("yyyy-MM-dd");
					const nowT = new Date().Format("yyyy-MM-dd hh:mm:ss");
					const { detail: gpsData } = await this.$http.post("/waybillActual/getGpsDataByDevice", {
						deviceNo: deviceId,
						beginTime: nowD + " 00:00:00",
						endTime: nowT
					});
					if (Array.isArray(gpsData)) {
						let arrPosi = [],
							landmarkPois = [];
						gpsData.forEach(v => {
							const [lng, lat] = gcoord.transform([v.lng, v.lat], gcoord.WGS84, gcoord.BD09);
							arrPosi.push(new BMapGL.Point(lng, lat));
							landmarkPois.push({
								lng: lng,
								lat: lat,
								html: `<div style="text-align:left;">
                                        <span>时间：${this.$util.fixedMacTime(v.time)}</span>
                                        <br />
                                        <span>速度：${v.spd}</span>
                                        <br />
                                        <span>行驶里程：${v.mileage}</span>
                                        <br />
                                        <span>经纬度：经度${lng}，纬度${lat}</span>
                                        <br />
                                        <span>方向角：${v.dir}</span>
                                    </div>`
							});
						});
						// 添加首尾标记
						this.addStartEndMarker(arrPosi[0], arrPosi[arrPosi.length - 1], startIcon, endIcon);

						this.trackMap.centerAndZoom(arrPosi[0], 15);
						// 添加polyline
						const polyline = new BMapGL.Polyline(arrPosi, {
							strokeColor: "blue", //设置颜色
							strokeWeight: 5, //宽度
							strokeOpacity: 0.5 //透明度
						});
						this.trackMap.addOverlay(polyline);
						// 添加路书
						this.lushu = new LuShu(this.trackMap, arrPosi, {
							defaultContent: "", //"从天安门到百度大厦"
							autoView: true, //是否开启自动视野调整，如果开启那么路书在运动过程中会根据视野自动调整
							icon: new BMapGL.Icon("https://developer.baidu.com/map/jsdemo/img/car.png", new BMapGL.Size(52, 26), {
								anchor: new BMapGL.Size(27, 13)
							}),
							speed: 300, //运行速度
							enableRotation: true, //是否设置marker随着道路的走向进行旋转
							landmarkPois: landmarkPois,
							showInfoWindow: true,
							listen: (index, total) => {
								const progress = Math.ceil((index / total) * 100);
								DataStore.target = "dataMap";
								this.dataStore.progress = progress;
								this.dataStore.trigger("progress", progress);
							}
						});
					}
				}
				this.startLushu();
			}
		},
		// 单纯将地图中心点设置为指定车辆的位置
		findCarByVehicleId(vehicleId) {
			const marker = this.getOverlay(vehicleId);
			marker && this.trackMap.centerAndZoom(marker.getPosition(), 15);
		}
	},
	components: {
		Station
	}
};
</script>
<style lang="scss" scoped>
.map-wrapper,
.map-container {
	height: 100%;
	position: relative;
}
.map-wrapper {
	.win_butt {
		position: absolute;
		top: 20px;
		right: 10px;
		img {
			width: 30px;
			margin-right: 10px;
			cursor: pointer;
		}
	}
}
</style>
