<template>
	<div class="real-run-main-wrapper">
		<site-line ref="line" v-for="op in options" :key="op.routeId" :routeId="op.routeId" :rType="op.rType"></site-line>
	</div>
</template>

<script>
import SiteLine from "../siteLine/line.vue";
export default {
	inject: ["dataStore"],
	mounted() {
		this.dataStore.on("routeIds", () => {
			this.focusLines();
		});
		this.dataStore.on("vehicleId", () => {
			this.focusCar();
		});
	},
	methods: {
		// 初始化线路
		focusLines(vehicleId) {
			this.$http
				.post("/route/info/queryRouteInfoByOrgIds", {
					routeIds: this.dataStore.routeIds.join(",")
				})
				.then(res => {
					this.options = res.detail;
					// 初始化车辆
					this.$nextTick(() => {
						const lineRefs = this.$refs.line;
						if (lineRefs) {
							lineRefs.forEach(lineRef => {
								lineRef.init(vehicleId);
							});
						}
					});
				});
		},
		focusCar() {
			// 选择车辆时，重置线路
			// this.$http
			//     .post("/route/info/queryRouteInfoByOrgIds", {
			//         routeIds: routeId
			//     })
			//     .then(res => {
			//         // this.setOptions(res.detail, vehicleId);
			//         this
			//     });
		}
	},
	data() {
		return {
			options: []
		};
	},
	components: {
		SiteLine
	}
};
</script>
<style>
.real-run-main-wrapper {
	padding-left: 300px;
	height: 100%;
	overflow: auto;
}
</style>
