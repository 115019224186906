<template>
	<el-tree
		default-expand-all
		class="vehicle_tree"
		ref="tree"
		v-loading="loading"
		node-key="id"
		lazy
		:load="loadNode"
		:props="treeProps"
		@node-click="handleNodeClick"
	>
		<span slot-scope="{ node, data }" class="tree_node_span">
			<span class="content_span" :style="{ color: data.online == 1 ? '#2ecc71' : 'black' }">
				<input
					class="checkbox"
					:checked="!isAdmin"
					:id="data.id"
					type="checkbox"
					v-if="data.checkbox"
					@change.stop="changeSelect(data, $event)"
				/>
				{{ data.text }}
				<span class="number">（共：{{ data.total }}辆）</span>
			</span>
		</span>
	</el-tree>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	inject: ["dataStore"],
	data() {
		return {
			loading: false,
			treeProps: {
				label: "text",
				children: "children",
				isLeaf: "leaf"
			}
		};
	},
	computed: {
		...mapGetters(["isAdmin"])
	},
	methods: {
		clearSelected() {
			this.dataStore.routeIds.forEach(routeId => {
				const checkbox = document.getElementById(routeId);
				if (checkbox) {
					checkbox.checked = false;
				}
			});
		},
		changeSelect(data, ev) {
			const checkbox = ev.target;
			if (checkbox.checked) {
				this.dataStore.routeIds.push(data.id);
			} else {
				const rIndex = this.dataStore.routeIds.findIndex(v => v === data.id);
				if (rIndex != -1) {
					this.dataStore.routeIds.splice(rIndex, 1);
				}
			}
			this.dataStore.trigger("routeIds");
		},
		getAllCount(orgId) {
			return this.$http
				.post(
					"/base/vehicle/queryVehicleCount",
					{
						orgId
					},
					{
						type: "format"
					}
				)
				.then(res => {
					return res.detail;
				});
		},
		handleNodeClick(data) {
			console.log(data);
			// const parent = this.$parent;
			// parent.$refs.vehicleComRef.init(data);
		},
		loadNode(node, resolve) {
			let id = "",
				type = 0;
			if (node.data && node.data.id) {
				id = node.data.id;
				type = node.data.type;
			}
			this.$http
				.post("/base/resource/getTree", {
					id,
					type
				})
				.then(async ({ detail }) => {
					let children = [],
						originList = detail[0].children;
					for (let index = 0; index < originList.length; index++) {
						const v = originList[index];
						if (v.type === 0) {
							children.push({
								text: v.data.orgNa,
								id: v.data.orgId,
								total: await this.getAllCount(v.data.orgId),
								checkbox: v.data.type === "2"
							});
						}
					}
					resolve(children);
				});
		},
		getOrgNames(id) {
			let node = this.$refs.tree.getNode(id),
				parent = node.data,
				orgNames = [];
			while (parent) {
				if (parent.type === 0) {
					orgNames.push(parent.text);
				}
				parent = parent.pData;
			}
			orgNames.reverse();
			return orgNames.join("");
		},
		initCheckedRouteIds() {
			this.$http
				.post("/route/info/queryRouteInfoByOrgIds", {
					routeIds: ""
				})
				.then(({ detail }) => {
					this.dataStore.routeIds = detail.map(v => v.routeId);
					this.dataStore.trigger("routeIds");
				});
		}
	},
	mounted() {
		if (!this.isAdmin) {
			this.initCheckedRouteIds();
		}
		/**
		 * 选择车辆的时候清空线路选择
		 */
		this.dataStore.on("vehicleId", () => {
			this.clearSelected();
			this.dataStore.routeIds = [];
		});
	}
};
</script>
<style lang="scss" scoped>
.vehicle_tree {
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.tree_node_span {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 12px;
	padding-right: 8px;
	font-weight: 600;

	.content_span {
		vertical-align: middle;
		color: #333333;

		&.online {
			color: #36ab60;
		}

		img {
			vertical-align: middle;
			margin-right: 2px;
			width: 16px;
		}
	}
}
</style>
