<template>
	<div class="timer-wrapper">
		<h3>{{ name }}发车时刻表</h3>
		<div v-if="!nothing">
			<div v-if="rType == '0'">
				<timer-table name="上行班次" :list="upList"></timer-table>
				<timer-table name="下行班次" :list="downList"></timer-table>
			</div>
			<div v-else>
				<timer-table name="环形班次" :list="circleList"></timer-table>
			</div>
		</div>
		<div class="nothing" v-else>暂无数据</div>
	</div>
</template>

<script>
// schemeT 1:上行 2:下行 3:环形
// rType: 1:上下行 2:环形
export default {
	props: {
		name: String,
		routeId: String,
		rType: String
	},
	mounted() {
		this.$watch(
			"routeId",
			() => {
				this.init();
			},
			{ immediate: true }
		);
	},
	data() {
		return {
			upList: [],
			downList: [],

			circleList: [],
			nothing: true
		};
	},
	methods: {
		resetFun() {
			this.upList = [];
			this.downList = [];
			this.circleList = [];
		},
		init() {
			this.resetFun();
			this.$http
				.post("/shift/real/time/queryList", {
					routeId: this.routeId,
					oDate: new Date().Format("yyyy-MM-dd")
				})
				.then(({ detail }) => {
					if (!detail || !detail.length) {
						this.nothing = true;
						return;
					}
					this.nothing = false;
					if (this.rType == 0) {
						detail.forEach(v => {
							if (v.schemeT == "1") {
								this.upList.push(v);
							} else if (v.schemeT == "2") {
								this.downList.push(v);
							}
						});
					} else {
						this.circleList = detail;
					}
				});
		}
	},
	components: {
		"timer-table": {
			props: {
				name: String,
				list: Array
			},
			render() {
				return (
					<div class="timer-table">
						<h3>{this.name}</h3>
						<div class="table-wrapper">
							<table>
								<thead>
									<tr>
										<td>发车时间</td>
										<td>车牌号码</td>
										<td>驾驶员</td>
										<td>发车状态</td>
									</tr>
								</thead>
								<tbody>
									{this.list.map(v => {
										return (
											<tr>
												<td>{v.dTime}</td>
												<td>{v.actualVehicleNo || v.vehicleNo}</td>
												<td>{v.driverNa}</td>
												<td>{v.eState == "1" ? "已发车" : "未发车"}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				);
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.timer-wrapper {
	.nothing {
		text-align: center;
	}
	h3 {
		text-align: center;
	}
	.timer-table {
		padding: 10px;
		& ::v-deep .table-wrapper {
			max-height: 330px;
			overflow-y: auto;
			table {
				width: 100%;
				text-align: center;
				border-collapse: collapse;
				thead {
					background-color: #dbdbdb;
				}
				tr {
					height: 30px;
					line-height: 30px;
				}
				&,
				& tr th,
				& tr td {
					border: 1px solid gray;
				}
			}
		}
	}
}
</style>
