import { render, staticRenderFns } from "./site.vue?vue&type=template&id=0948cd15&scoped=true"
import script from "./site.vue?vue&type=script&lang=js"
export * from "./site.vue?vue&type=script&lang=js"
import style0 from "./site.vue?vue&type=style&index=0&id=0948cd15&prod&lang=scss&scoped=real_time_site"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0948cd15",
  null
  
)

export default component.exports